import { request } from "."

export const getRetailerInfo = async (key) => {
  const endpoint = `zoho/v1/leadinfo?key=${key}`
  const data = await request(endpoint)
  return data
}

export const updateRetailerCount = async (ids) => {
  const endpoint = `wp/v2/retailer?ids=${ids}`
  const data = await request(endpoint)
  return data
}

export const updateMemberClickCount = async (id) => {
  const endpoint = `wp/v2/member?id=${id}`
  const data = await request(endpoint)
  return data
}

export const trackRetailers = async (retailers) => {
  if (!retailers || retailers.length === 0) {
    return null
  }

  await Promise.all(
    retailers.map((retailer) => {
      const {
        databaseId,
        title,
        states,
        Retailer: {
          googleMap: { streetAddress }
        }
      } = retailer

      const state = states?.nodes?.[0]?.slug

      if (process.env.GATSBY_GOOGLE_ANALYTICS) {
        if (typeof window !== `undefined` && typeof window.ga === `function`) {
          window.ga(`send`, `event`, {
            eventCategory: `Retailer View`,
            eventAction: `view`,
            eventLabel: title,
            eventValue: 1,
            dimension1: databaseId,
            dimension2: state,
            dimension3: streetAddress
          })
        }
      }
      return null
    })
  )

  return null
}
