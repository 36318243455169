import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { graphql, navigate, Link } from "gatsby"
import "maplibre-gl/dist/maplibre-gl.css"
import ReactMapboxGl, { ZoomControl, Marker, Cluster, Popup } from "react-mapbox-gl"
import MapboxClient from "mapbox"
import ReactTable, { ReactTableDefaults } from "react-table"
import slugify from "slugify"
import { TextField, Typography, IconButton } from "@material-ui/core"
import { Locate as LocateIcon } from "react-ionicons"
import Parser from "html-react-parser"

// import app components
import Edges from "components/edges"
import Spacer from "components/spacer"
import Pagination from "components/pagination"
import Button from "components/button"
import Geocoder from "components/geocoder"
import MarkerIcon from "../../../../static/images/icons/marker.svg"
import MarkerClusterIcon from "../../../../static/images/icons/markerCluster.svg"
import { useStore } from "store"
import latLngDistanceCalculation from "utils/latLngDistanceCalculation"
import sortBy from "utils/sortBy"
import { getUrlPath } from "utils/getUrlPath"
import * as theme from "theme"
import { retailerServices } from "services"

// let Geocoder
let Map = false
let mapboxClient = false

if (typeof window !== `undefined` && process.env.GATSBY_MAPBOX) {
  Map = ReactMapboxGl({
    accessToken: process.env.GATSBY_MAPBOX
  })

  mapboxClient = new MapboxClient(process.env.GATSBY_MAPBOX)
}

const mapAccess = {
  mapboxApiAccessToken: process.env.GATSBY_MAPBOX
}

// Custom pagination
Object.assign(ReactTableDefaults, {
  PaginationComponent: Pagination
})

// Default settings
const maxDistance = 200
const retailersPerPage = 25

const FindADealerTemplate = (props) => {
  const {
    data: {
      allWpRetailer: { nodes: retailers },
      wp: {
        themeSettings: {
          siteOptions: { globalQuoteButton }
        }
      }
    }
  } = props

  const [
    {
      appState: { geoInformation }
    },
    dispatch
  ] = useStore()

  let defaultLocation = { latitude: 0, longitude: 0 }

  if (geoInformation?.location?.latitude && geoInformation?.location?.longitude) {
    defaultLocation = { latitude: geoInformation.location.latitude, longitude: geoInformation.location.longitude }
  }

  // User location, either auto geocode or manual search
  const [location, setLocation] = useState({ ...defaultLocation, state: "" })
  const [map, setMap] = useState(null)

  // all retailers filtered by state/province and distance
  const [filteredRetailers, setFilteredRetailers] = useState(null)

  // paginated retailers on map
  const [page, setPage] = useState(0)
  const [member, setMember] = useState(null)
  const [members, setMembers] = useState([])
  const [popup, setPopup] = useState({ longitude: 0, latitude: 0, text: null })

  // if member (= id) is set then filter out all retailers of member
  // otherwise filter out big retailer stores and add single item to the top instead
  let listRetailers = filteredRetailers
  if (member) {
    listRetailers = filteredRetailers.filter((o) => o.Retailer?.premiumRetailer?.id === member)
  } else {
    listRetailers =
      filteredRetailers &&
      filteredRetailers.filter(
        (o) => !o.Retailer.premiumRetailer || !o.Retailer?.premiumRetailer?.Members?.excludeFromOrganicSearchResults
      )
    members.map((o) => {
      if (o.Members.excludeFromOrganicSearchResults) {
        return listRetailers && listRetailers.unshift(o)
      } else {
        return members
      }
    })
  }

  // map retailers are list retailers filtered by active page
  // in here are also members to make it consistent with the sidebar. We'll remove them later.
  const mapRetailers =
    listRetailers && listRetailers.slice(page * retailersPerPage, page * retailersPerPage + retailersPerPage)

  // We have to transform the map retailers to a simple string to detect changes in useEffect
  const mapRetailersString = JSON.stringify(
    mapRetailers &&
      mapRetailers.map((o) => {
        return { id: o.id }
      })
  )

  useEffect(() => {
    if (mapRetailers) {
      // Remove members from map retailers
      const visibileRetailers = mapRetailers.filter((o) => !!o?.Retailer?.googleMap)
      updateRetailerCount(visibileRetailers)
      fitBounds(visibileRetailers)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRetailersString])

  useEffect(() => {
    if (map && geoInformation) {
      const latitude = geoInformation?.location?.latitude
      const longitude = geoInformation?.location?.longitude
      const state = geoInformation?.subdivisions?.[0]?.names?.en

      // Save geo information
      setLocation({ latitude, longitude, state })

      calculateRetailers(retailers, latitude, longitude, state)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geoInformation, map])

  const onLoad = (map) => {
    // Center map over North America
    map.fitBounds(
      [
        [-141, 68],
        [-54, 26]
      ],
      { duration: 0 }
    )
    setMap(map)
  }

  const getAvailableMembers = (newRetailers) => {
    // Extract members from premium retailers
    const availableMembers = []
    newRetailers &&
      newRetailers.map(({ Retailer: { premiumRetailer } }) => {
        if (premiumRetailer && availableMembers.findIndex((p) => p.id === premiumRetailer.id) === -1) {
          return availableMembers.push(premiumRetailer)
        } else {
          return availableMembers
        }
      })

    setMembers(availableMembers)
  }

  const calculateRetailers = (retailers, latitude, longitude, state, updateMembers = true) => {
    let newRetailers = retailers

    // Filter retailers by state
    if (state) {
      newRetailers = retailers.filter(
        (o) => o?.states?.nodes[0]?.slug === slugify(state, { lower: true }) && o.Retailer?.googleMap
      )
    }

    // Add distance
    newRetailers = newRetailers.map((o) => {
      const distance = latLngDistanceCalculation(
        o.Retailer?.googleMap?.latitude,
        o.Retailer?.googleMap?.longitude,
        latitude,
        longitude
      )

      return { ...o, distance }
    })

    newRetailers = newRetailers.filter((o) => o.distance < maxDistance)

    if (updateMembers) {
      getAvailableMembers(newRetailers)
    }

    // Sort retailers by distance
    sortBy(newRetailers, "distance", "asc")

    // Add to state
    setFilteredRetailers(newRetailers)
  }

  const onPageChange = (page) => {
    setPage(page)
    handleClosePopup()
  }

  const fitBounds = (retailer) => {
    if (retailer.length > 0) {
      // Get min and max values
      const maxLat = Math.max.apply(
        Math,
        retailer.map((o) => o.Retailer.googleMap.latitude)
      )
      const minLat = Math.min.apply(
        Math,
        retailer.map((o) => o.Retailer.googleMap.latitude)
      )
      const maxLng = Math.max.apply(
        Math,
        retailer.map((o) => o.Retailer.googleMap.longitude)
      )
      const minLng = Math.min.apply(
        Math,
        retailer.map((o) => o.Retailer.googleMap.longitude)
      )

      if (!!maxLat && !!minLat && !!maxLng && !!minLng) {
        map.fitBounds(
          [
            [maxLng, maxLat],
            [minLng, minLat]
          ],
          {
            padding: { top: 10, bottom: 10, left: 10, right: 10 }
          }
        )
      }
    } else {
      map.fitBounds(
        [
          [location.longitude + 1, location.latitude + 1],
          [location.longitude - 1, location.latitude - 1]
        ],
        {
          padding: { top: 1, bottom: 1, left: 1, right: 1 },
          duration: 0
        }
      )
    }
  }

  const handleClosePopup = () => setPopup({ longitude: 0, latitude: 0, text: null })

  const onMemberClick = (id, databaseId) => {
    setPage(0)
    handleClosePopup()

    if (id === member) {
      onResetMember()
    } else {
      setMember(id)
      updateMemberCount(databaseId)

      const memberRetailers = retailers.filter((o) => o.Retailer?.premiumRetailer?.id === id)
      calculateRetailers(memberRetailers, location.latitude, location.longitude, location.state, false)
    }
  }

  const onResetMember = () => {
    setPage(0)
    setMember(null)
    handleClosePopup()
    calculateRetailers(retailers, location.latitude, location.longitude, location.state)
  }

  const updateRetailerCount = (newRetailers) => {
    retailerServices.trackRetailers(newRetailers)

    // const ids = newRetailers.map((o) => o.databaseId)

    // if (ids.length > 0) {
    //   retailerServices.updateRetailerCount(ids.toString())
    // }
  }

  const updateMemberCount = (id) => {
    retailerServices.updateMemberClickCount(id)
  }

  const onSidebarItemClick = (item) => {
    map.fitBounds([
      [item.Retailer.googleMap.longitude + 0.001, item.Retailer.googleMap.latitude + 0.001],
      [item.Retailer.googleMap.longitude - 0.001, item.Retailer.googleMap.latitude - 0.001]
    ])

    setPopup({
      longitude: item.Retailer.googleMap.longitude,
      latitude: item.Retailer.googleMap.latitude,
      text: (
        <>
          <Typography variant="h4" children={Parser(item.title)} />
          <div>
            <Typography variant="caption" children={item.Retailer.googleMap.streetAddress} />
          </div>
          {item.Retailer.phone && <Typography variant="caption" children={item.Retailer.phone} />}
        </>
      )
    })
  }

  const handleSelect = (viewport, item) => {
    const { latitude, longitude } = viewport
    const { context } = item

    setPage(0)
    setMember(null)
    handleClosePopup()

    const region = context.length > 0 ? context.find((o) => o.id.includes("region")) : null
    const state = region ? region.text : ""

    // Save geo information
    setLocation({ latitude, longitude, state })

    calculateRetailers(retailers, latitude, longitude, state)
  }

  const clusterMarker = (coordinates) => (
    <Marker key={JSON.stringify(coordinates)} coordinates={coordinates}>
      <StyledMarkerClusterIcon />
    </Marker>
  )

  const handleLocate = () => {
    setMember(null)
    handleClosePopup()

    if (mapboxClient && navigator.geolocation) {
      dispatch({ type: "SET_PROGRESS", payload: true })

      navigator.geolocation.getCurrentPosition(async (position) => {
        if (position?.coords?.longitude && position?.coords?.latitude) {
          const result = await mapboxClient.geocodeReverse({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          })

          let state = ""

          const {
            entity: { features }
          } = result

          if (features && features.length > 0) {
            state = features.find((o) => o.id.includes("region"))
              ? features.find((o) => o.id.includes("region")).text
              : ""
          }

          setLocation({ longitude: position.coords.longitude, latitude: position.coords.latitude, state })
          calculateRetailers(retailers, position.coords.latitude, position.coords.longitude, state)
        }

        dispatch({ type: "SET_PROGRESS", payload: false })
      })
    } else {
      console.error("Geolocation is not supported by this browser!")
    }
  }

  return (
    <Edges size="lg">
      {!globalQuoteButton && (
        <Announcement>
          The Request a Quote functionality is temporarily disabled to make room for new and upcoming changes to the
          website. Please stay tuned for these exciting new changes and features coming soon!
        </Announcement>
      )}
      <Wrapper>
        <Container>
          <MapContainer>
            <GeoLookupContainer component="div">
              <LocateButton onClick={handleLocate}>
                <LocateIcon color="#fff" />
              </LocateButton>
              <GeocoderContainer>
                {typeof window !== "undefined" && (
                  <Geocoder
                    {...mapAccess}
                    viewport={location}
                    onSelected={handleSelect}
                    hideOnSelect={true}
                    updateInputOnSelect={true}
                    inputComponent={(onChange) => (
                      <TextField fullWidth {...onChange} placeholder="Enter a location..." />
                    )}
                    queryParams={{
                      country: ["us", "ca"]
                    }}
                  />
                )}
              </GeocoderContainer>
            </GeoLookupContainer>

            {typeof window !== "undefined" && (
              <Map
                // eslint-disable-next-line
                style="mapbox://styles/mapbox/streets-v8"
                onStyleLoad={onLoad}
                maxZoom={18}
                movingMethod="jumpTo"
                onClick={handleClosePopup}
                maxBounds={[
                  [-160, 20],
                  [-50, 70]
                ]}
              >
                <ZoomControl />

                {mapRetailers && (
                  <Cluster zoomOnClick ClusterMarkerFactory={clusterMarker}>
                    {mapRetailers
                      .filter((o) => !!o?.Retailer?.googleMap)
                      .map((o) => {
                        return (
                          <Marker
                            key={o.id}
                            coordinates={[o.Retailer.googleMap.longitude, o.Retailer.googleMap.latitude]}
                            onClick={() =>
                              setPopup({
                                longitude: o.Retailer.googleMap.longitude,
                                latitude: o.Retailer.googleMap.latitude,
                                text: (
                                  <>
                                    <Typography variant="h4" children={Parser(o.title)} />
                                    <div>
                                      <Typography variant="caption" children={o.Retailer.googleMap.streetAddress} />
                                    </div>
                                    {o.Retailer.phone && <Typography variant="caption" children={o.Retailer.phone} />}
                                  </>
                                )
                              })
                            }
                          >
                            <StyledMarkerIcon />
                          </Marker>
                        )
                      })}
                  </Cluster>
                )}

                {location.longitude && location.latitude && (
                  <Marker coordinates={[location.longitude, location.latitude]}>
                    <StyledMarkerIcon variant="location" />
                  </Marker>
                )}

                {popup.text && (
                  <Popup
                    coordinates={[popup.longitude, popup.latitude]}
                    offset={{
                      "bottom-left": [12, -38],
                      bottom: [0, -38],
                      "bottom-right": [-12, -38]
                    }}
                  >
                    {popup.text}
                  </Popup>
                )}
              </Map>
            )}
          </MapContainer>

          <Sidebar hasPagination={listRetailers && listRetailers.length > retailersPerPage}>
            {member && (
              <ResetButton
                className="show-all-results"
                fullWidth
                variant="contained"
                children="All search results"
                onClick={onResetMember}
              />
            )}
            {listRetailers && listRetailers.length > 0 ? (
              <StyledReactTable
                member={!!member}
                data={listRetailers}
                onPageChange={onPageChange}
                page={page}
                showPageSizeOptions={false}
                defaultPageSize={retailersPerPage}
                showPageJump={false}
                sortable={false}
                minRows={0}
                columns={[
                  {
                    Header: "",
                    accessor: "title",
                    width: "100%",
                    Cell: (o) => {
                      return (
                        <>
                          {o.original.Members ? (
                            <SidebarItem
                              className={`premium-retailer-exclude`}
                              onClick={() => onMemberClick(o.original.id, o.original.databaseId)}
                            >
                              {o.original.Members?.smallMemberLogo?.sourceUrl && (
                                <SidebarItemLogo>
                                  <img
                                    src={o.original.Members.smallMemberLogo.sourceUrl}
                                    alt="Logo"
                                    style={{ maxHeight: "60px" }}
                                  />
                                </SidebarItemLogo>
                              )}
                              <SidebarItemHeadline variant="h4" children={Parser(o.value)} />
                              <SidebarItemText children={`Multiple Locations`} />
                              <SidebarItemText children={`Click for closest locations`} />
                            </SidebarItem>
                          ) : (
                            <SidebarItem onClick={() => onSidebarItemClick(o.original)}>
                              {o.original?.Retailer?.premiumRetailer?.Members?.smallMemberLogo?.sourceUrl && (
                                <SidebarItemLogo>
                                  <img
                                    src={o.original.Retailer.premiumRetailer.Members.smallMemberLogo.sourceUrl}
                                    alt="Logo"
                                  />
                                </SidebarItemLogo>
                              )}
                              <SidebarItemHeadline variant="h4" children={Parser(o.value)} />
                              <SidebarItemText children={o.original?.Retailer.googleMap?.streetAddress} />
                              <SidebarItemText children={o.original?.Retailer.phone} />
                              <SidebarItemText
                                variant="caption"
                                children={`${parseFloat(o.original.distance).toFixed(2)} miles`}
                              />

                              <SitebarItemLinks component="div">
                                {/* eslint-disable jsx-a11y/control-has-associated-label */}
                                <a
                                  href={`https://www.google.com/maps/dir/?api=1&origin=${location.latitude},${location.longitude}&destination=${o.original?.Retailer?.googleMap?.latitude},${o.original?.Retailer?.googleMap?.longitude}`}
                                  target="_blank"
                                  children="Directions"
                                  rel="noopener noreferrer"
                                />
                                {o.original?.Retailer?.premiumRetailer?.uri && (
                                  <Link
                                    to={getUrlPath(o.original.Retailer.premiumRetailer.uri)}
                                    children={`Get Details`}
                                  />
                                )}
                              </SitebarItemLinks>

                              {globalQuoteButton && o.original?.Retailer.quoteButton && o.original?.Retailer.email && (
                                <Spacer pt={10}>
                                  <QuoteButton
                                    to={`/retail-quote-request?id=${o.original.databaseId}`}
                                    // onClick={() => navigate()}
                                    children={`Get Quote`}
                                  />
                                </Spacer>
                              )}
                            </SidebarItem>
                          )}
                        </>
                      )
                    }
                  }
                ]}
              />
            ) : (
              <NoResults children={location.longitude && location.latitude ? "No Results" : ""} />
            )}
          </Sidebar>
        </Container>

        {members.length > 0 && (
          <MemberContainer size="md">
            <Spacer pt={20}>
              <MemberHeadline variant="caption" children="Visit Our Premium Retailers Below" />
            </Spacer>

            <MemberItemsContainer>
              {members.map((o) => {
                return (
                  <MemberItem
                    key={o.id}
                    onClick={() => onMemberClick(o.id, o.databaseId)}
                    deactivate={member && member !== o.id}
                    className={`premium-retailer`}
                  >
                    <MemberImageContainer>
                      {o.Members?.smallMemberLogo?.sourceUrl && (
                        <img src={o.Members.smallMemberLogo.sourceUrl} alt="Logo" />
                      )}
                    </MemberImageContainer>
                    <MemberTitle key={o.id} children={o.post_title} />
                  </MemberItem>
                )
              })}
            </MemberItemsContainer>
          </MemberContainer>
        )}
      </Wrapper>
    </Edges>
  )
}

const Wrapper = styled.div`
  position: relative;
`

const Announcement = styled(Typography)`
  && {
    padding: 20px;
    margin: 20px 0;
    background: ${theme.colors.secondary};
    color: #fff;
  }
`

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 20px 0px;

  @media (min-width: 960px) {
    height: 460px;
  }

  .mapboxgl-popup {
    max-width: 250px;
  }
`

const GeoLookupContainer = styled(Typography)`
  position: relative;
  display: flex;
  align-items: center;
  background: ${theme.colors.primary};
  height: 60px;
`

const LocateButton = styled(IconButton)`
  position: absolute;
  z-index: 10;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
`

const GeocoderContainer = styled.div`
  width: 100%;

  input {
    height: 36px;
    width: 100%;
    border: none;
    background: transparent;
    padding: 12px 12px 12px 60px;
    font-size: 16px;
    color: #fff;
  }

  .react-geocoder-results {
    position: absolute;
    top: 100%;
    left: 0%;
    width: 100%;
    height: auto;
    z-index: 100;
    background: #fff;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
  }

  .react-geocoder-item {
    padding: 8px 12px;
    transition: ease all 0.2s;
    cursor: pointer;

    &:hover {
      background: #f2f2f2;
    }
  }
`

const MemberContainer = styled.div`
  padding-bottom: 40px;
`

const MemberItemsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

const MemberHeadline = styled(Typography)`
  text-align: center;
  text-transform: uppercase;
  display: block;
  margin-bottom: 20px;
`

const MemberItem = styled.div`
  width: 100%;
  margin: 0 10px 20px;
  cursor: pointer;
  transition: ease all 0.4s;
  opacity: ${(props) => (props.deactivate ? 0.4 : 1)};

  &:hover {
    transform: scale(1.05);
  }

  @media (min-width: 460px) {
    width: calc(50% - 20px);
  }

  @media (min-width: 640px) {
    width: calc(100% / 4 - 20px);
  }

  @media (min-width: 960px) {
    width: calc(100% / 5 - 20px);
  }
`

const MemberImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  margin-bottom: 10px;
  padding: 20px;
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);

  img {
    max-height: 100%;
    max-width: 100%;
  }
`

const MemberTitle = styled(Typography)`
  text-align: center;
  font-size: 12px;
`

const Sidebar = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: ${(props) => (props.hasPagination ? "36px" : 0)};
  background: #fff;

  @media (min-width: 960px) {
    width: 350px;
    height: 460px;
    order: 1;
  }
`

const SidebarItem = styled.div`
  padding: 15px;
  border-top: 1px solid ${theme.colors.lightgrey};
  border-left: 1px solid ${theme.colors.lightgrey};
  cursor: pointer;
`

const SidebarItemLogo = styled.div`
  float: right;
  width: 90px;
  max-height: 100%;
  margin-bottom: 20px;
  margin-left: 10px;
  display: flex;
  justify-content: flex-end;
`

const SidebarItemHeadline = styled(Typography)`
  font-size: 14px;
`

const SidebarItemText = styled(Typography)`
  font-size: 13px;
`

const SitebarItemLinks = styled(Typography)`
  a {
    display: inline-block;
    font-size: 13px;
    margin-right: 20px;
  }
`

const QuoteButton = styled(Button)`
  && {
    font-size: 12px;
    padding: 8px 20px 6px;
    border-radius: 30px;
    background: ${theme.colors.secondary};
    color: #fff;
    font-weight: bold;
    line-height: 12px;
    letter-spacing: 0.02em;

    &:hover:before {
      background: #03a03c;
    }

    svg {
      margin-left: 10px;
    }
  }
`

const StyledReactTable = styled(ReactTable)`
  && {
    border: none;

    @media (min-width: 960px) {
      height: ${(props) => (props.member ? "calc(100% - 60px)" : "100%")};
    }

    .rt-table {
      height: 100%;
      overflow: auto;
    }

    .rt-th {
      display: none;
    }

    .rt-td {
      white-space: pre-wrap;
      padding: 0;
    }

    .rt-tr-group {
      flex: none;
    }

    .-loading {
      display: none;
    }
  }
`

const MapContainer = styled.div`
  position: relative;
  height: 360px;

  .mapboxgl-map {
    height: 300px;
  }

  @media (min-width: 960px) {
    height: 460px;
    order: 2;

    .mapboxgl-map {
      height: 400px;
    }
  }

  flex: 1;
`

const ResetButton = styled(Button)`
  height: 60px;
`

const NoResults = styled(Typography)`
  padding: 20px;
`

const StyledMarkerIcon = styled(MarkerIcon)`
  width: 36px !important;
  height: 36px !important;
  cursor: pointer;

  path {
    fill: ${(props) => (props.variant === "location" ? theme.colors.secondary : theme.colors.primary)};
  }
`

const StyledMarkerClusterIcon = styled(MarkerClusterIcon)`
  width: 36px !important;
  height: 36px !important;
  cursor: pointer;

  path {
    fill: ${theme.colors.primary};
  }
`

export const CollectionQuery = graphql`
  query FindADealerTemplate($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      slug
      uri
      acfModules {
        hideSidebar
        hideSearch
      }
    }
    wp {
      themeSettings {
        siteOptions {
          globalQuoteButton
        }
      }
    }
    allWpRetailer(filter: { Retailer: { googleMap: { streetAddress: { ne: "" } } } }) {
      nodes {
        id
        databaseId
        title
        states {
          nodes {
            slug
          }
        }
        Retailer {
          googleMap {
            latitude
            longitude
            streetAddress
            city
            country
            state
          }
          phone
          quoteButton
          email
          premiumRetailer {
            ... on WpMember {
              id
              databaseId
              uri
              title
              Members {
                excludeFromOrganicSearchResults
                smallMemberLogo {
                  sourceUrl
                }
              }
            }
          }
        }
      }
    }
  }
`

export default FindADealerTemplate
